<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>app版本编辑</div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button></div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form  label-width="100px" ref="info" :model="info">
                    <el-form-item label="平台" prop="F_VR_PINGT" >
                        <el-radio-group v-model="info.F_TI_PINGT">
                            <el-radio :label="1">Android</el-radio>
                            <el-radio :label="2">ios</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="状态" prop="F_TI_ZHUANGT" :rules="[{required:true,message:'状态不能为空',trigger: 'blur' }]">
                        <el-select v-model="info.F_TI_ZHUANGT" placeholder="选择状态">
                            <el-option label="最新版本" value="1"></el-option>
                            <el-option label="强制升级" value="2"></el-option>
                            <el-option label="提示升级" value="3"></el-option>
                            <el-option label="不提示升级" value="4"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="版本号" prop="F_VR_BANBH" :rules="[{required:true,message:'版本号不能为空',trigger: 'blur' }]"><el-input placeholder="请输入版本号" v-model="info.F_VR_BANBH"></el-input></el-form-item>
                    <el-form-item label="下载链接" prop="F_VR_XIAZDZ" :rules="[{required:true,message:'下载链接不能为空',trigger: 'blur' }]"><el-input placeholder="请输入下载链接" v-model="info.F_VR_XIAZDZ"></el-input></el-form-item>

                    <el-form-item label="更新描述" prop="F_TE_GENGXMS" :rules="[{required:true,message:'更新描述不能为空',trigger: 'blur' }]"><el-input type="textarea"  placeholder="请输入更新描述" v-model="info.F_TE_GENGXMS" :rows="8"></el-input></el-form-item>

                    <!--<el-form-item label="更新描述" class="width_auto_70" :rules="[{required:true,message:'更新描述不能为空',trigger: 'blur' }]">
                        <wangeditor @goods_content="goods_content" :contents="info.F_TE_GENGXMS"></wangeditor>
                    </el-form-item>-->
                    
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                        <el-button @click="resetForm('info')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import wangeditor from "@/components/seller/wangeditor.vue"
export default {
    components: {
        wangeditor,
    },
    props: {},
    data() {
      return {
          edit_id:0,
          info:{
              F_TI_PINGT:1,
              F_TI_ZHUANGT:'',
              F_VR_BANBH:'',
              F_VR_XIAZDZ:'',
              F_TE_GENGXMS:'',
          },
          list:[],
          upload_headers:{},
      };
    },
    watch: {},
    computed: {},
    methods: {
        resetForm:function(e){
            this.$refs[e].resetFields();
        },
        submitForm:function(e){
            let _this = this;
            // 验证表单
            this.$refs[e].validate(function(res){
                if(res){
                    //  判断是Add 或者 Edit
                    let url = _this.$api.addAppbb;
                    if(_this.edit_id>0){
                        url = _this.$api.editAppbb+_this.edit_id;
                    }
                    // Http 请求
                    _this.$post(url,_this.info).then(function(res){
                        if(res.code == 200){
                            _this.$message.success("编辑成功");
                            _this.$router.go(-1);
                        }else{
                            _this.$message.error("编辑失败");
                        }
                    });
                }
            });
        },
        get_agreement_info:function(){
            let _this = this;
            this.$get(this.$api.editAppbb+this.edit_id).then(function(res){
                _this.info = res.data;
                /*if(_this.info.F_TI_ZHUANGT ==1){
                    _this.info.F_TI_ZHUANGT='最新版本';
                }else if(_this.info.F_TI_ZHUANGT ==2){
                    _this.info.F_TI_ZHUANGT='强制升级';
                }else if(_this.info.F_TI_ZHUANGT ==3){
                    _this.info.F_TI_ZHUANGT='提示升级';
                }else{
                    _this.info.F_TI_ZHUANGT='不提示升级';
                }*/




            })

        },
        // 富文本编辑内容变化
        goods_content:function(data){
            this.info.F_TE_GENGXMS = data;
        },

        handleAvatarSuccess(res) {
            this.info.F_VR_FENGM = res.data;
            this.$forceUpdate();
        },
       
    },
    created() {
        this.upload_headers.Authorization = 'Bearer '+localStorage.getItem('token'); // 要保证取到
        // 判断是否是编辑
        this.info.pid = 0;
        if(!this.$isEmpty(this.$route.params.F_IN_ID)){
            this.edit_id = this.$route.params.F_IN_ID;
        }

        if(this.edit_id>0){
            this.get_agreement_info();
        }
        
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>

</style>